.site-layout .site-layout-background {
    background-color: white;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin: 16px;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }