@import url('https://fonts.googleapis.com/css?family=Nunito|Open+Sans&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
}

.amplify-tabs {
  display: none;
}
